import { useEffect, useState } from 'react';
import Funerals from './Funerals/Funerals';
import HospitalVisits from './HospitalVisits/HospitalVisits';
import './page.css'
import Topbar from './topbar/Topbar';
import useWindowDimensions from './Assets/useWindowDimensions'
import axios from 'axios'

/**
 * Used to establish the main page of the pco board
 * @component
 */
function App() {
  const [hospitalVisits, setHosiptalVisits] = useState([])
  const [funerals, setFunerals] = useState([])

  // used to determine whether to use vertical or horizontal POC layout
  const { height, width } = useWindowDimensions()

  const wideDisplay = width / height > 1.5 ? true : false

  var extention = process.env.NODE_ENV === 'development' ? '.dev' : '.work'
  const endpoint = 'https://pocboard.sagebrush' + extention + '/poc_board_api'

  /**
   * called to grab the hospital visits and funerals that are stored in the database
   * @returns 
   * @func
   */
  const getUpcomingSchedules = async () => {
    return await axios({
      method: "get",
      url: endpoint + "/getUpcomingHospitalVisitsAndFunerals",
      withCredentials: true,
    }).then((msg) => {
      setHosiptalVisits(msg.data.hospitalVisits)
      setFunerals(msg.data.funerals)
    })
  }

  useEffect(() => {
    getUpcomingSchedules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const updateVal = () => {
    if (val === 0) {
      setVal(1)
    } else {
      setVal(0)
    }
  }

  const [val, setVal] = useState(0)

  useEffect(() => {
    const timer = setTimeout(updateVal, 1000 * 30)
    getUpcomingSchedules()
    return () => clearTimeout(timer);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [val]);

  return (
    <div className="pageBackground">
      <div className='pageTopbar'>
        <Topbar />
      </div>
      <div className={wideDisplay ? 'pageBodyWide' : 'pageBodyHeight'}>
        <div className={wideDisplay ? 'pageWidgetLeft' : 'pageWidgetTop'}>
          <HospitalVisits hospitalVisits={hospitalVisits} wideDisplay={wideDisplay} />
        </div>
        <div className={wideDisplay ? 'pageWidgetRight' : 'pageWidgetBottom'}>
          <Funerals funerals={funerals} wideDisplay={wideDisplay} />
        </div>
      </div>
    </div>
  );
}

export default App;
