import React from 'react'
import './funerals.css'
/**
 * @typedef Funeral
 * @type {object}
 * @prop {Number} id unique id of the funeral
 * @prop {string} dt datetime string of when the funeral actually is
 * @prop {string} name who the funeral is for
 * @prop {string} location where the funeral is at
 * @prop {string} pastor the pastor leading the funeral
 * @prop {string} notes any information one might want to share about the person or funeral
 * @prop {string} startShowing when the funeral will show up on the POC Board
 * @prop {string} stopShowing when the funeral will stop showing on the POC board
 * @prop {string} startTime same at dt but in a easy to read form
 */

/**
 * Creates the Funeral Table on the POC board.
 * @param {object} props
 * @prop {Array<Funeral>} funerals funerals that need to be displayed on the board
 * @prop {boolean} wideDisplay whether or not to use the vertical or horizontal layout
 * @component
 */
const Funerals = ({ funerals, wideDisplay }) => {
  return (
    <>
      <div className='funeralHeader'>
        <h1>FUNERALS</h1>
      </div>
      <div className={wideDisplay ? 'funeralSchedulesWide' : 'funeralSchedulesHeight'}>
        <div className='funeralEntriesFlexBox'>
          <div className='funeralDT' style={{ fontSize: '14px' }}><label>Date/Time</label></div>
          <div className='funeralName' style={{ fontSize: '14px' }}><label>Name</label></div>
          <div className='funeralLoc' style={{ fontSize: '14px' }}><label>Location</label></div>
          <div className='funeralPastor' style={{ fontSize: '14px' }}><label>Pastor</label></div>
          <div className='funeralNotes' style={{ fontSize: '14px' }}><label>Notes</label></div>
        </div>
        <div className='allFuneralEntries'>
          {funerals.map((funeral, index) => {
            return (
              <div
                className='funeralEntries'
                key={index}
              >
                <div className='funeralDT'><label>{funeral.startTime}</label></div>
                <div className='funeralName'><label>{funeral.name}</label></div>
                <div className='funeralLoc'><label>{funeral.location}</label></div>
                <div className='funeralPastor'><label>{funeral.pastor}</label></div>
                <div className='funeralNotes'><label>{funeral.notes}</label></div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default Funerals