import React from 'react'
import './hospitalVisits.css'
/**
 * @typedef HospitalVisit
 * @type {object}
 * @prop {Number} id unique id of the hospital visit
 * @prop {string} dt datetime string of when the hospital visit actually is
 * @prop {string} name The person in the hospital
 * @prop {string} location what hospital the patient is at
 * @prop {string} pastor the pastor that is visiting the hospital
 * @prop {string} updates Any updates on the persons condition
 * @prop {string} comment inital comment about the person in the hospital (what happened to them)
 * @prop {string} startShowing when the funeral will show up on the POC Board
 * @prop {string} stopShowing when the funeral will stop showing on the POC board
 * @prop {string} startTime same at dt but in a easy to read form
 */

/**
 * Creates the Hospital Visits Table on the POC board.
 * @param {object} props 
 * @prop {Array<HospitalVisit>} hospitalVisits that need to be displayed on the board
 * @prop {boolean} wideDisplay whether or not to use the vertical or horizontal layout
 * @component 
 */
const HospitalVisits = ({ hospitalVisits, wideDisplay }) => {
  return (
    <>
      <div className='hospitalHeader'>
        <h1>HOSPITAL VISITS</h1>
      </div>
      <div className={wideDisplay ? 'hospitalSchedulesWide' : 'hospitalSchedulesHeight'}>
        <div className='hospitalLabelsFlexBox'>
          <div className='hospitalDT' style={{ fontSize: '14px' }}><label>Date/Time</label></div>
          <div className='hospitalName' style={{ fontSize: '14px' }}><label>Name</label></div>
          <div className='hospitalLoc' style={{ fontSize: '14px' }}><label>Location</label></div>
          <div className='hospitalPastor' style={{ fontSize: '14px' }}><label>Pastor</label></div>
          <div className='hospitalUpdate' style={{ fontSize: '14px' }}><label>Update</label></div>
          <div className='hospitalComment' style={{ fontSize: '14px' }}><label>Comment</label></div>
        </div>
        <div className='allHospitalEntries'>
          {hospitalVisits.map((visit, index) => {
            return (
              <div
                className='hospitalEntries'
                key={index}
              >
                <div className='hospitalDT'><label>{visit.startTime}</label></div>
                <div className='hospitalName'><label>{visit.name}</label></div>
                <div className='hospitalLoc'><label>{visit.location}</label></div>
                <div className='hospitalPastor'><label>{visit.pastor}</label></div>
                <div className='hospitalUpdate'><label>{visit.updates}</label></div>
                <div className='hospitalComment'><label>{visit.comment}</label></div>
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default HospitalVisits