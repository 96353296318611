import React from 'react'
import './topbar.css'
import SBLogo from '../Images/SBLogo.png'

/**
 * Creates the top bar on the POC board
 * @component
 */
const Topbar = () => {
  return (
    <div className='topbarStyling'>
      <div className='topbarItem' style={{ paddingLeft: '50px' }}>
        S A G E B R U S H
      </div>
      <div className='topbarItem' style={{ justifyContent: 'center' }}>
        <img
          style={{ maxHeight: '50px', color: '#cdcdcd' }}
          src={SBLogo}
          alt={''}
        />
      </div>
      <div className='topbarItem' style={{ justifyContent: 'flex-end', paddingRight: '50px' }}>
        <label>
          P A S T O R A L
        </label>
        <label style={{ paddingLeft: '15px' }}>C A R E</label>
      </div>
    </div>
  )
}

export default Topbar